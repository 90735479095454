<route>
{
  "meta": {
    "permission": [
      "base_config.view_productiondepartment"
    ]
  }
}
</route>

<template>
  <div>
    <dialdepartment
      v-model="dialog"
      :to-edit="toEdit"
      :max="true"
      @done="reload = true"
    />
    <i-table
      app="base_config.productiondepartment"
      api="department"
      :headers="headers"
      :opt="{ company_id: company.pk }"
      :reload.sync="reload"
      :title="$tc('department', 2)"
      @click:create="open()"
      @click:edit="open($event)"
    >
      <template v-slot:item.description="{ item }">
        <span class="template-list" v-html="item.description"></span>
      </template>
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialdepartment from '../../components/department/modal.vue'
export default {
  components: {
    dialdepartment
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('description'),
          value: 'description'
        },
        {
          text:
            this.departmentBtn.delete || this.departmentBtn.edit
              ? this.$t('action')
              : '',
          value:
            this.departmentBtn.delete || this.departmentBtn.edit
              ? 'actions'
              : ''
        }
      ]
    },
    ...mapGetters({
      company: 'company/getCompanyData',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    departmentBtn() {
      return {
        delete:
          this.getPermissions([`base_config.delete_productiondepartment`]) ||
          this.isAdmin,
        edit:
          this.getPermissions([`base_config.change_productiondepartment`]) ||
          this.isAdmin
      }
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>
<style lang="sass">
.template-list > p
  margin-bottom: 0
  & + p
    margin-top: 4px
</style>
